<template>
  <div id="tab5" class="tab_content_area">
    <div class="img_wrap" style="height:300px;">
      <img src="../../../assets/images/trans/con_frsr.png">
    </div>
    <div class="container_tbl_wrap">
      <div class="tbl_tit">{{ $t('msg.NEWB010P050.004') }}</div><!-- SUPER RACK CONTAINER -->
      <div class="tbl_wrap">
        <table class="tbl_col">
          <colgroup>
            <col style="width:14%">
            <col style="width:auto">
            <col style="width:14%">
            <col style="width:14%">
            <col style="width:14%">
            <col style="width:14%">
            <col style="width:14%">
          </colgroup>
          <thead>
            <tr>
              <th scope="col">Type of <br>Container</th>
              <th scope="col">Internal<br>Dimension<br>(mm)</th>
              <th scope="col">Door<br>Opening<br>(mm)</th>
              <th scope="col">Load<br>Capacity<br>(m<sup>3</sup>)</th>
              <th scope="col">Container<br>Tare Weight<br>(Kg)</th>
              <th scope="col">Max <br>Pay Load<br>(Kg)</th>
              <th scope="col">Max <br>Gross Weight<br>(Kg)</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <th scope="row">40SR</th>
              <td>L: 11,552<br>W: 2,374<br>H: 3,483</td>
              <td>W: 2,374<br>H: 3,483</td>
              <td>96.0</td>
              <td>5,600</td>
              <td>39,400</td>
              <td>45,000</td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'ContainerSR'
}
</script>

<style scoped>

</style>
